import React from "react";
import ReactDOM from "react-dom";
import Map from "./map";
import mapboxgl from "mapbox-gl";

window.addEventListener("load", () => {
  mapboxgl.accessToken         = window.mapboxToken;
  const mapboxContainer        = document.getElementById("mapbox");
  const templateRdvCenters     = document.querySelector('.page-template-rdv-centers');
  const templateCenters        = document.querySelector('.page-template-centers');
  const templatePartnerCenters = document.querySelector('.page-template-partner-centers');
  var getMap = async function() {
    ReactDOM.render(
        <React.StrictMode>
          <Map />
        </React.StrictMode>,
        mapboxContainer
    );
  };


  if (mapboxContainer) {
    if (!templateRdvCenters && !templatePartnerCenters && !templateCenters) {
      window.addEventListener("scroll", () => {
        getMap();
      });
    }
    else {
      getMap();
    }

  }
});